<!--
 * @Author: wyq
 * @Date: 2021-06-15 11:05:14
 * @LastEditTime: 2021-10-21 15:57:37
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\detail.vue
-->
<template>
	<page-container class="page-detail" asideTitle="视频状态">
		<div class="aside" slot="aside">
			<div v-if="userType == 1">
				<el-form label-position="top" size="mini">
					<el-form-item label="管理员审核">
						<el-select v-model="checkForm.checkStatus" placeholder="未审核">
							<el-option label="通过" :value="2"></el-option>
							<el-option label="不通过" :value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button
							type="success"
							size="medium"
							@click="submitCheckForm"
							:disabled="
								videoDetail.checkStatus != 1 ||
								(videoDetail.checkStatus == 1 && !checkForm.checkStatus)
							"
						>
							提交
						</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="status-box">
				<p>当前审核状态</p>
				<div v-if="videoDetail.checkStatus == 3" class="status-circular warning">
					审核未通过
				</div>
				<div v-else-if="videoDetail.checkStatus == 2" class="status-circular success">
					审核通过
				</div>
				<div v-else class="status-circular disabled">待审核</div>
			</div>
		</div>
		<content-container title="视频信息">
			<div v-if="videoDetail" class="same-source-test">
				<publicVideoPlayer
					:poster="videoDetail.coverPhotoUrl"
					:sources="videoSources"
				></publicVideoPlayer>
				<p class="card-content">
					<span v-if="videoDetail.url" style="color:#71b0aa">视频转码成功</span>
					<template v-else>
						视频转码失败
						<el-button type="text" @click="refreshVideo">点击刷新视频</el-button>
					</template>
				</p>
				<p class="card-content">
					<strong>标题：</strong>
					{{ videoDetail.title }}
				</p>
				<p class="card-content">
					<strong>电视呈现标题：</strong>
					{{ videoDetail.tvTitle }}
				</p>
				<p class="card-content">
					<strong>摘要：</strong>
					{{ videoDetail.abstract }}
				</p>
				<p class="card-content">
					<strong>视频标签：</strong>
					{{ videoDetail.videoLabel }}
				</p>
				<p class="card-content">
					<strong>知识点标签：</strong>
					{{ videoDetail.labelName }}
				</p>
				<p class="card-content">
					<strong>更新时间：</strong>
					{{ $moment.unix(videoDetail.updateTimeAt).format('YYYY-MM-DD HH:mm') }}
				</p>
				<el-button type="primary" size="small" @click="editVideo">编辑</el-button>
				<el-button type="danger" size="small" @click="deleteVideo">删除</el-button>
			</div>
		</content-container>
		<content-container title="编辑记录">
			<el-table :data="editRecordList" header-cell-class-name="header-cell-style">
				<el-table-column label="编辑时间">
					<template slot-scope="scope">
						<div>{{ scope.row.editTimeAt | datefmt('YYYY-MM-DD HH:mm:ss') }}</div>
					</template>
				</el-table-column>
				<el-table-column prop="content" label="编辑内容"></el-table-column>
				<el-table-column prop="editUser" label="编辑者"></el-table-column>
			</el-table>
			<!-- <el-pagination
				background
				layout="prev, pager, next"
				:current-page.sync="currentPage"
				:page-size="pageSize"
				:total="total"
				@current-change="getQuestionHistory"
				hide-on-single-page
			></el-pagination> -->
		</content-container>
	</page-container>
</template>
<script>
import publicVideoPlayer from '../components/publicVideoPlayer.vue'
import 'videojs-contrib-hls'
export default {
	components: {
		publicVideoPlayer,
	},
	data() {
		return {
			currentPage: 1,
			pageSize: 10,
			total: 0,
			checkForm: {
				checkStatus: null,
			},
			motherQuestionId: null,
			currentId: null,
			previewVisiable: false,
			videoDetail: {},
			editRecordList: [],
			videoSources: [],
		}
	},
	computed: {
		userType() {
			return localStorage.getItem('userType')
		},
		currentVideoId() {
			return Number(this.$route.query.id)
		},
	},
	methods: {
		init() {
			if (this.currentVideoId) {
				this.$service.getVideoDetail({ id: this.currentVideoId }).then((res) => {
					this.videoDetail = res.videoDetail
					this.editRecordList = res.editRecordList
					this.checkForm.checkStatus =
						res.videoDetail.checkStatus == 1 ? null : res.videoDetail.checkStatus
					this.videoSources = [
						{
							type: 'application/x-mpegURL',
							src: res.videoDetail.url,
						},
						{
							type: 'video/mp4',
							src: res.videoDetail.obsUrl,
						},
					]
				})
			}
		},
		submitCheckForm() {
			const params = {
				id: this.currentVideoId,
				checkStatus: this.checkForm.checkStatus,
			}
			this.$service.updateVideoStatus(params).then(() => {
				this.$message({
					type: 'success',
					message: '提交成功!',
				})
				this.init()
			})
		},
		editVideo() {
			this.$router.push({
				name: 'AddVideo',
				query: {
					id: this.currentVideoId,
				},
			})
		},
		deleteVideo() {
			this.$confirm('是否确认删除视频？', '删除确认', {
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				cancelButtonClass: 'el-button--primary is-plain',
				confirmButtonClass: 'el-button--danger',
			})
				.then(() => {
					this.$service.delVideo({ id: this.currentVideoId }).then(() => {
						this.$message({
							type: 'success',
							message: '删除成功!',
						})
						setTimeout(() => {
							this.$router.go(-1)
						}, 500)
					})
				})
				.catch(() => {})
		},
		refreshVideo() {
			this.$service
				.flushVideo({
					id: this.currentVideoId,
				})
				.then(() => {
					this.$message({
						type: 'success',
						message: '刷新完成!',
					})
					this.init()
				})
		},
	},
	mounted() {
		this.init()
	},
}
</script>
<style lang="scss" scoped>
.page-detail {
	.aside {
		padding: 24px 32px;
		.status-box {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;

			::v-deep .el-form-item__label {
				color: #fff;
			}
			& > p {
				font-size: 18px;
			}
			.status-circular {
				width: 94px;
				height: 94px;
				margin: 10px auto;
				border-radius: 50%;
				font-size: 16px;
				line-height: 94px;
				&.warning {
					background-color: #d90e0e;
				}
				&.success {
					background-color: #00ba95;
				}
				&.disabled {
					background-color: #d8d8d8;
				}
			}
			.reason {
				margin-top: 30px;
				font-size: 16px;
				text-align: left;
			}
		}
	}
	.content-container + .content-container {
		margin-top: 24px;
	}
	.same-source-test {
		border-top: 1px solid #f0f0f0;
		padding: 24px 0;
	}
	::v-deep .header-cell-style {
		background-color: #fafafa;
		color: #333333;
	}
	::v-deep .cell {
		padding-left: 26px;
	}
	.el-table {
		margin-bottom: 40px;
	}
}
</style>
<style>
.video-js .vjs-big-play-button {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.card-content {
	font-size: 14px;
}
</style>
